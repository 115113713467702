import React from "react";
import "./Greeting.css";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import { greeting } from "../../portfolio";
import {Fade} from "react-reveal";
import emoji from "react-easy-emoji";

export default function Greeting() {
  return (
    <Fade bottom duration={1000} distance="40px">
    <div className="greet-main" id="greeting">
      <div className="greeting-main">
        <div className="greeting-text-div">
          <div>
            <h1 className="greeting-text">
              {" "}
              {greeting.title}
              {" "}
            </h1>
            <p className="greeting-text-p subTitle">{greeting.subTitle}</p>
            <SocialMedia />
            <div className="button-greeting-div">
              <Button text="Uzraksti man" href="#contact" />
            </div>
          </div>
        </div>
		
        <div className="greeting-image-div">
		  <div className="greeting-pic-cnt">
			<img alt="saad sitting on table" src="https://www.martinsz.lv/images/portfolio/martinz5.jpg" className="greeting-pic-pic-round"></img>
		  </div>
        </div>
      </div>
    </div>
    </Fade>
  );
}
