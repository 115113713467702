import React from "react";
import "./Header.css";
import {Fade} from "react-reveal";
import {greeting} from "../../portfolio";

function Header() {
  return (
    <Fade top duration={1000} distance="20px">
    <div>
      <header className="header">
        <a href="" className="logo">
          <span className="grey-color"> &lt;</span>
          <span className="logo-name">{greeting.username}</span>
          <span className="grey-color">/&gt;</span>
        </a>
		
        <input className="menu-btn" type="checkbox" id="menu-btn" />
    
        <ul className="menu">
          
        </ul>
      </header>
    </div>
    </Fade>
  );
}
export default Header;
