import React, { Component } from "react";
import Header from "../components/header/Header";
import Greeting from "./greeting/Greeting";
import Skills from "./skills/Skills";
import StackProgress from "./skillProgress/skillProgress";
import Projects from "./projects/Projects";
import StartupProject from "./StartupProjects/StartupProject";
import Achievement from "./achievement/Achievement";
import Blogs from "./blogs/Blogs";
import Contact from "./contact/Contact";
import Footer from "../components/footer/Footer";
import Talks from "./talks/Talks";
import Podcast from "./podcast/Podcast";
import Top from "./topbutton/Top";
import Twitter from "./twitter-embed/twitter";
import Profile from "./profile/Profile";
import "./Main.css";

export default class Main extends Component {
  render() {
    return (
      <div>
        <Header />
        <Greeting />
        <Skills />
        <StackProgress />
        <Achievement />
		
        <div className="main">
          <h1 className="heading achievement-heading sjzysgemu">Atsauksmes</h1>

      		<div className="bb7hpj8ar">
            <img src="https://www.martinsz.lv/images/andris.jpg" className="wbsemh5cu"></img>
      			<div className="xzypfayps">Andris Taubers</div>
            <div className="hazc5cnjq">CEO, Arkbauer Group</div>
            <div className="jptxhwzrc"></div>

            <div className="kbp2xdvkn">"Ar Mārtiņu Zeltiņu esmu sadarbojies vairākos projektos kopš 2010. gada. Ļoti novērtēju viņa čaklumu iedziļināties visās projekta tehniskajās detaļās un piegādāt gatavu, iztestētu rezultātu."</div>
      		</div>
        </div>
		
        <Profile />
        <Footer />
        <Top />
      </div>
    );
  }
}
